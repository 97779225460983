.content_bg {
    background-image: url("../../public/bubbles.jpg");
    height: 20rem;
    background-size: cover;
    background-repeat: no-repeat;
    width: "100%";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  .title {
    font-size: 2.6rem;
    line-height: 3.75rem;
    text-align: center;
    font-weight: 700;
  }
  .para1 {
    font-size: 1.2rem;
    text-align: center;
    font-weight: 400;
    margin-top: 20px;
  }
  
  