.react-calendar { width: 350px; max-width: 100%; background: white; font-family: 'Lato', sans-serif; line-height: 1.125em;}
.react-calendar--doubleView { width: 700px; }
.react-calendar--doubleView .react-calendar__viewContainer { display: flex; margin: -0.5em;}
.react-calendar__navigation__arrow{ font-size: 3rem !important; line-height: 0 !important; margin-top: 0 !important; padding-top: 0 !important;background-color: #008EFF !important; }
.react-calendar__navigation{ height: 3.5rem !important; padding-bottom: 0.5rem !important; background-color: #008EFF !important; }
.react-calendar--doubleView .react-calendar__viewContainer>* { width: 50%; margin: 0.5em; }
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }
.react-calendar button { margin: 0; border: 0; outline: none; }
.react-calendar button:enabled:hover { cursor: pointer;}
.react-calendar__navigation { display: flex; height: 44px; background-color: #75a8dc; }
.react-calendar__navigation button { min-width: 44px; background: none; }
.react-calendar__navigation button:disabled { color: #ffffff8a !important;}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus { background-color: #75a8dc; }
.react-calendar__month-view__weekdays { text-align: center; text-transform: uppercase; font-weight: bold; font-size: 0.75em; }
.react-calendar__month-view__weekdays__weekday { padding: 0.5em; }
.react-calendar__month-view__weekNumbers .react-calendar__tile { display: flex; align-items: center; justify-content: center; font-size: 0.75em; font-weight: bold; }
/* .react-calendar__month-view__days__day--weekend { color: #d10000; } */
.react-calendar__month-view__days { background-color: #f0f0f0; }
.react-calendar__month-view__days__day--neighboringMonth { color: #757575; }
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile { padding: 2em 0.5em; }
.react-calendar__tile { max-width: 100%; padding: 10px 6.6667px; background: none; text-align: center; line-height: 16px; }
.react-calendar__tile:disabled { background-color: #f0f0f0; }
.react-calendar__tile:disabled.react-calendar__tile--now { background: #FFFFCC; }
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus { background-color: #e6e6e6; }
.react-calendar__tile--now { background: #ffff76 !important; }
.react-calendar__tile--now:enabled:hover{background: #ffffb3 !important; color: black !important; },
.react-calendar__tile--now:enabled:focus { background: #75a8dc; }
.react-calendar__tile--hasActive { background: #e6e6e6; }
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus { background: #e6e6e6; }
.react-calendar__tile--active { background: #006edc; color: white;}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus { background: #1087ff; }
.react-calendar--selectRange .react-calendar__tile--hover { background-color: #e6e6e6; }
.react-calendar { width: 50%; max-width: 100%; color: #222;  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem; }
.react-calendar__navigation button { color: #fff; min-width: 44px; font-size: 1rem; font-weight: 700; background: none; font-size: 16px; margin-top: 8px; font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem; }
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {}
.react-calendar__navigation button[disabled] { background-color: #e5eefd; }
abbr[title] { text-decoration: none; }
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus { background: #dcfff7; color: #000; }
.react-calendar__tile--now { background: #dcfff7; font-weight: bold; color: #000; }
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus { background: #6f48eb33; font-weight: bold; color: #fff; }
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus { font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem; background: #000; }
.react-calendar__tile--active { border: 1px solid black !important; background: #dcfff7; font-weight: bold; color: #000; font-family: 'Lato', sans-serif; font-weight: 700;  line-height: 1.37rem; }
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus { background: #f8f8fa; color: #000; font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem; }
.react-calendar--selectRange .react-calendar__tile--hover { background-color: #f8f8fa; font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem; }
.react-calendar__tile--range { background: #f8f8fa; color: #dcfff7; font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem; }
.react-calendar__tile--rangeStart { background: #dcfff7; color: #000; font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem; }
.react-calendar__tile--rangeEnd { background: #dcfff7; color: #000; font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem; }
.react-calendar__navigation button[disabled] { background-color: #75a8dc; font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem;}
.react-calendar__month-view__weekdays { background-color: #e5eefd; text-align: center; text-transform: uppercase; font-weight: bold; font-size: 0.75em; font-family: 'Lato', sans-serif; font-weight: 700; line-height: 1.37rem;}
.react-calendar__navigation__label{pointer-events: none !important;}
.react-calendar__month-view__days__day{background-color: #a8f8f7}
@media only screen and (max-width: 768px) { .react-calendar {width: 90%;}}