.Wrapper {
    display: flex;
    flex-direction: row;
  }
  
  .headerName {
    color: #35bfff;
    font-size: 2em;
    font-weight: 300;
    line-height: 1em;
    margin-top: 0;
    text-align: center;
    font-family: "Lato", sans-serif;
  
  
  }
  
  .Left {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  .Right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  #Img_Pane {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .Login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
  .paragraph {
    color: '#666666';
    font: 'Lato,sans-serif';
    padding: '0px, 0px,16px';
    font-size: 1rem;
  }
  
  
  .textpara {
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: 100;
    text-align: 'left';
    padding: 0 20px;
    
  }