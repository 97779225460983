@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
}

.p-Field {
  display: none !important;
}

body {

  background-color: #fff;
  margin-top: 4rem;
  font-family: "Lato", sans-serif;

  /*add this for fix order flow header margin issue */
  /* margin-left: 0;
  margin-right: 0; */
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none !important;
}